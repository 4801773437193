<template>
  <div
    id="app"
    class="text-sm lg:text-base"
    :class="{ 'blur-sm': !isTenantReady }"
  >
    <DesktopMenu v-if="isLoggedIn" class="fixed top-0 z-40 hidden lg:block">
      <template #default="{ isNavOpen }">
        <DesktopMenuItems :is-nav-open="isNavOpen" />
      </template>
    </DesktopMenu>
    <RouterView />
    <MobileMenu v-if="mobileMenuShown" />
    <Notification />
  </div>
</template>

<script lang="ts" setup>
import { computed, watch, ref, type ComputedRef } from "vue";
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n";
import { useAddToHomescreen } from "@owliehq/vue-addtohomescreen";

import Notification from "@core/src/components/Notification.vue";
import DesktopMenu from "@shared/src/components/DesktopMenu.vue";
import MobileMenu from "@/components/MobileMenu.vue";
import DesktopMenuItems from "@/components/DesktopMenuItems.vue";

import type ITenant from "@core/src/types/ITenant";

import { useTenantStore } from "@/store/Tenant";
import { useAuthStore } from "@/store/Auth";
import { useFavicon } from "@core/src/useFavicon";

const route = useRoute();
const tenantStore = useTenantStore();
const authStore = useAuthStore();

const { t } = useI18n();

const isLoggedIn = computed(() => {
  return authStore.isLoggedIn;
});

const mobileMenuShown = computed(() => {
  return isLoggedIn.value && route.name !== "profile";
});

const browserLang = navigator.language;

// 1. addToHomeScreen node module uses the language in the format of "de_DE"
// 2. Check if the browser language is _any_ of the French languages
const addToHomescreenLang = computed(() => {
  if (browserLang.includes("fr")) {
    return "fr_FR";
  }
  // German is default
  return "de_DE";
});

// Sniff if mobile
function isMobile() {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );
}

// Sniff if PWA
function isPWA() {
  return window.matchMedia("(display-mode: standalone)").matches;
}

function callUseAddToHomescreen() {
  // Run useAddToHomescreen only on mobile browsers
  if (isMobile() && !isPWA()) {
    useAddToHomescreen({
      title: t("addToHomescreen.title").toString(),
      content: t("addToHomescreen.content").toString(),
      iconPath: "../public/pwa-192x192.png",
      buttonColor: "#134cb9",
      expires: 7,
      lang: addToHomescreenLang.value,
    });
  }
}

// @ts-ignore some weird errors in the cli type checker
const tenant: ComputedRef<ITenant> = computed(() => tenantStore.tenant);

// @ts-ignore some weird errors in the cli type checker
useFavicon(tenant);
const isTenantReady = ref(false);

/**
 * Show the page blurry until the tenant messages are ready
 * this is to avoid the misleading display of the language keys
 */
watch(tenant, (tenant: ITenant) => {
  if (tenant) {
    isTenantReady.value = true;
    callUseAddToHomescreen();
  }
});
</script>

<style lang="postcss" scoped>
#app {
  @apply relative min-h-screen bg-grayTpf-50;
}
</style>
