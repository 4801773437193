const initializeMatomo = (envVariables: any) => {
  // @ts-ignore
  const _paq = (window._paq = window._paq || []);
  /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
  const siteId = envVariables?.VITE_MATOMO_SITE_ID;
  if (siteId !== "" && siteId !== undefined) {
    _paq.push(["trackPageView"]);
    _paq.push(["enableLinkTracking"]);
    (function () {
      const u = "https://matomo-stopsmoking.ch/";
      _paq.push(["setTrackerUrl", u + "matomo.php"]);
      _paq.push(["setSiteId", siteId]);
      const d = document,
        g = d.createElement("script"),
        s = d.getElementsByTagName("script")[0];
      g.async = true;
      g.src = u + "matomo.js";
      if (!s.parentNode) {
        return;
      }
      s.parentNode.insertBefore(g, s);
    })();
  }
};

export { initializeMatomo };
